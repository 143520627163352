import React from 'react';
import FacebookSvg from '../../images/socials/facebook.inline.svg';
import InstaSvg from '../../images/socials/instagram.inline.svg';
import TwitterSvg from '../../images/socials/twitter.inline.svg';
import YouTubeSvg from '../../images/socials/youtube.inline.svg';
import * as styles from './social_links.module.less';

export function SocialLinks({}) {
  return (
    <ul className={styles.socialList}>
      <li className={styles.socialItem}>
        <a href="http://www.twitter.com/bassutopia" className={styles.socialLink}>
          <TwitterSvg />
        </a>
      </li>
      <li className={styles.socialItem}>
        <a href="http://www.facebook.com/bassutopia" className={styles.socialLink}>
          <FacebookSvg />
        </a>
      </li>
      <li className={styles.socialItem}>
        <a href="http://instagram.com/bassutopia" className={styles.socialLink}>
          <InstaSvg />
        </a>
      </li>
      <li className={styles.socialItem}>
        <a href="http://www.youtube.com/user/bassutopiatv/featured" className={styles.socialLink}>
          <YouTubeSvg />
        </a>
      </li>
    </ul>
  );
}
