import classNames from 'classnames';
import React from 'react';
import * as base from '../../styles/base.module.less';

export interface ContentWrapperProps {
  className?: string;
  noPadding?: boolean;
  mobileNoPadding?: boolean;
  noOverflow?: boolean;
  style?: React.CSSProperties;
  tag?: 'section' | 'div';
}

export const ContentWrapper: React.SFC<ContentWrapperProps> = (props) => {
  const { mobileNoPadding, noPadding, noOverflow, tag, className, style } = props;
  const classnames = classNames(base.contentWrapper, className, {
    [base.contentWrapper__noPadding]: noPadding,
    [base.contentWrapper__mobileNoPadding]: mobileNoPadding,
    [base.contentWrapper__noOverflow]: noOverflow,
  });
  const ElementName = tag != null ? tag : 'div';
  return (
    <ElementName className={classnames} style={style}>
      {props.children}
    </ElementName>
  );
};
