import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { SiteConfig } from '../config';

export interface SeoProps {
  title?: string;
  titleTemplate?: string;
  description?: string;
  meta?: Array<
    | {
        name: string;
        content: string;
      }
    | {
        property: string;
        content: string;
      }
  >;
  path?: string;
  ogType?: 'website' | 'article';
  ogImage?: string;
}

export const SEO: React.FC<SeoProps> = props => {
  const { title, description, titleTemplate, meta = [], ogType = `website`, ogImage, path } = props;

  let metas = [
    {
      property: `og:type`,
      content: ogType as string,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
  ];

  const helmetProps: HelmetProps = {
    meta: metas,
  };

  if (titleTemplate) {
    helmetProps.titleTemplate = titleTemplate;
  }

  if (title) {
    helmetProps.title = title;
    metas = [
      ...metas,
      {
        property: `og:title`,
        content: title,
      },
      {
        name: `twitter:title`,
        content: title,
      },
    ];
  }

  if (ogImage) {
    metas = [
      ...metas,
      {
        property: `og:image`,
        content: ogImage,
      },
    ];
  }

  if (path) {
    metas = [
      ...metas,
      {
        property: `og:url`,
        content: `${SiteConfig.siteUrl}${path}`,
      },
    ];
  }

  if (description) {
    metas = [
      ...metas,
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ];
  }

  helmetProps.meta = [...metas, ...meta];

  return <Helmet {...helmetProps}>{props.children}</Helmet>;
};
