export enum RoutePaths {
  ABOUT = '/about/',
  ARTICLES = '/articles/',
  ARTICLES_LIST = '/articles_list/',
  CONTRIBUTORS = '/contributors/',
  GIVEAWAYS = '/giveaways/',
  PARTNERS = '/partners/',
  SERIES = '/series/',
  TECHNIQUES = '/techniques/',
}

export const OMNIA_URL = 'https://www.omniafishing.com';

export enum OmniaRoutePaths {
  ABOUT = '/about',
  AMBASSADOR_APPLICATION = '/ambassador-application',
  ARTICLES = '/a',
  BRANDS = '/b',
  CATEGORIES = '/c',
  CONFIRM = '/confirm',
  DASHBOARD = '/dashboard',
  DEALS = '/deals',
  DELIVERY_RETURN = '/delivery-return',
  EMAIL_SENT = '/email-sent',
  HOME = '/',
  HOW_IT_WORKS = '/how-it-works',
  LISTS = '/l',
  LOGIN = '/login',
  MAP = '/map',
  PASSWORD_RESET = '/password-reset',
  PASSWORD_RESET_CONFIRM = '/password-reset-confirm',
  PREMIUM = '/premium',
  PRIVACY = '/privacy',
  PRODUCTS = '/p',
  RECOMMENDATIONS = '/recommendations',
  SEARCH = '/search',
  SHOP = '/shop',
  SIGNUP = '/signup',
  SIGNUP_GIVEAWAY = '/signup_giveaway',
  SPECIES = '/sp',
  STYLES = '/st',
  STATES = '/states',
  TERMS = '/terms',
  TOURNAMENT_SUBMIT = '/tournament-submit',
  TOURNAMENTS = '/tournaments',
  USERS = '/u',
  WATERS = '/w',
  WHO = '/who',
  WISHLIST = '/wishlist',
}
