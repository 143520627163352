import algoliasearch from 'algoliasearch/lite';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { Highlight, Hits, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import usePortal from 'react-useportal';
import { RoutePaths } from '../../lib/routes';
import * as styles from './search.module.less';

interface AlgoliaArticle {
  article_summary: string;
  thumbnail: string;
  title: string;
  uid: string;
  objectID: string;
}

function Hit(props: { hit: AlgoliaArticle }) {
  const { hit } = props;
  const { thumbnail, title, uid } = hit;
  return (
    <Link to={`${RoutePaths.ARTICLES}${uid}`} className={styles.hit}>
      <div className={styles.hitImg}>
        <img src={thumbnail} alt={title} loading="lazy" />
      </div>
      <div>
        <div className={styles.hitName}>
          <Highlight attribute="title" hit={hit} />
        </div>
        <div className={styles.hitSummary}>
          <Highlight attribute="article_summary" hit={hit} />
        </div>
      </div>
    </Link>
  );
}

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
);

export const Search = () => {
  const { openPortal, closePortal, isOpen, Portal } = usePortal();
  return (
    <div>
      <div className={styles.iconWrapper} onClick={openPortal}>
        <span className={styles.iconText}>Search Site</span> <FaSearch />
      </div>

      <Portal>
        <div
          className={classNames(styles.modal, {
            [styles.open]: isOpen,
            [styles.closed]: !isOpen,
          })}
          onClick={closePortal}
        >
          <div
            className={styles.modalContent}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className={styles.heading}>Search</h2>
            {isOpen && (
              <InstantSearch indexName="articles_dev" searchClient={searchClient}>
                <SearchBox
                  autoFocus
                  submit={null}
                  focusShortcuts={[]}
                  translations={{
                    placeholder: 'Search for gear, lakes, techniques, etc...',
                  }}
                />
                <Hits hitComponent={Hit} />
              </InstantSearch>
            )}
          </div>
          <button type="button" onClick={closePortal} className={styles.close}>
            ✕
          </button>
        </div>
      </Portal>
    </div>
  );
};
