import { useState, useEffect } from 'react';

export enum Breakpoints {
  'sm' = 576,
}

export function useIsMobile() {
  const isClient = typeof window === 'object';

  function getIsMobile() {
    if (!isClient) {
      return true;
    }
    return window.innerWidth < Breakpoints.sm;
  }

  const [isMobile, setIsMobile] = useState(getIsMobile);

  useEffect(() => {
    function handleResize() {
      setIsMobile(getIsMobile());
    }

    if (isClient) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (isClient) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return isMobile;
}
