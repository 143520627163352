// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--2CJfC";
export var content = "footer-module--content--3QRRU";
export var section = "footer-module--section--nIU4X";
export var section__socials = "footer-module--section__socials--3doqI";
export var sectionContent = "footer-module--sectionContent--l0qXk";
export var navList = "footer-module--navList--iwZCC";
export var navItem = "footer-module--navItem--zr2Ka";
export var signupBlurb = "footer-module--signupBlurb--3Uv59";
export var link = "footer-module--link---dGbC";
export var mailLink = "footer-module--mailLink--3GeBq";
export var socials = "footer-module--socials--1FRz2";
export var copyright = "footer-module--copyright--1D-7l";