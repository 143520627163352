import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { RoutePaths } from '../../lib/routes';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { SignupForm } from '../signup_form/signup_form';
import { SocialLinks } from '../social_links/social_links';
import * as styles from './footer.module.less';

export const Footer: React.FC = (props) => (
  <footer className={styles.footer}>
    <ContentWrapper>
      <div className={styles.content}>
        <section className={styles.section}>
          <div className={styles.sectionContent}>
            <p className={styles.signupBlurb}>Stay in the loop!</p>
            <SignupForm />
          </div>
        </section>

        <section className={styles.section}>
          <div className={styles.sectionContent}>
            <nav>
              <ul className={styles.navList}>
                <li className={styles.navItem}>
                  Contact Us:{' '}
                  <a href="mailto:info@bassutopia.com" className={styles.mailLink}>
                    info@bassutopia.com
                  </a>
                </li>
                <li className={styles.navItem}>
                  <Link to={RoutePaths.ABOUT} className={styles.link}>
                    About Bass Utopia ›
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <section className={classNames(styles.section, styles.section__socials)}>
          <div className={styles.sectionContent}>
            <div className={styles.socials}>
              <SocialLinks />
            </div>
            <small className={styles.copyright}>© {new Date().getFullYear()} Bass Utopia</small>
          </div>
        </section>
      </div>
    </ContentWrapper>
  </footer>
);
