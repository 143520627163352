// extracted by mini-css-extract-plugin
export var header = "header-module--header--2dmT7";
export var content = "header-module--content--xFXGQ";
export var menuButton = "header-module--menuButton--2Ohqy";
export var menuButton__active = "header-module--menuButton__active--2N4V8";
export var menuIcon = "header-module--menuIcon--3TywD";
export var menus = "header-module--menus--B_1Lf";
export var menus__open = "header-module--menus__open--1BLl3";
export var logo = "header-module--logo--1EMZo";
export var mainNav = "header-module--mainNav--1OBLT";
export var mainNavList = "header-module--mainNavList--3lFf9";
export var mainNavLink = "header-module--mainNavLink--2mf6f";
export var secondNav = "header-module--secondNav--3pX57";
export var secondNavList = "header-module--secondNavList--3Pldd";
export var secondNavLink = "header-module--secondNavLink--nfJEF";
export var socials = "header-module--socials--3XLef";