// extracted by mini-css-extract-plugin
export var h1 = "base-module--h1--1vLtZ";
export var h2 = "base-module--h2--1-tYZ";
export var h3 = "base-module--h3--onc4k";
export var h4 = "base-module--h4--DSOc1";
export var h5 = "base-module--h5--5UNg6";
export var h6 = "base-module--h6--eGAqd";
export var link = "base-module--link--1ohqN";
export var site = "base-module--site--27FIU";
export var gatsbyWrapper = "base-module--gatsby-wrapper--2r5Tc";
export var main = "base-module--main--20zzM";
export var contentWrapper = "base-module--contentWrapper--3ztEB";
export var contentWrapper__noPadding = "base-module--contentWrapper__noPadding--2tIv0";
export var contentWrapper__mobileNoPadding = "base-module--contentWrapper__mobileNoPadding--2yw8B";
export var contentWrapper__noOverflow = "base-module--contentWrapper__noOverflow--21VaG";