// extracted by mini-css-extract-plugin
export var wrapper = "search-module--wrapper--22Hmm";
export var heading = "search-module--heading--3_qMa";
export var modal = "search-module--modal--42Onp";
export var open = "search-module--open--21gH6";
export var closed = "search-module--closed--WLMj0";
export var iconWrapper = "search-module--iconWrapper--RLANp";
export var iconText = "search-module--iconText--1Ewf1";
export var modalContent = "search-module--modalContent--3b9F7";
export var close = "search-module--close--23Ynt";
export var hit = "search-module--hit--1m4T3";
export var hitImg = "search-module--hitImg--2Tzqk";
export var hitName = "search-module--hitName--12ieU";
export var hitSummary = "search-module--hitSummary--1gEab";