import React from 'react';
import * as styles from './signup_form.module.less';

export const SignupForm: React.FC<{ className?: string }> = (props) => {
  return (
    <form
      action="https://www.getdrip.com/forms/699895778/submissions"
      method="post"
      data-drip-embedded-form="699895778"
      className={props.className}
    >
      <div className={styles.inputWrapper}>
        <input
          type="email"
          name="fields[email]"
          defaultValue=""
          placeholder="email address"
          className={styles.formInput}
        />
        <button type="submit" className={styles.formButton}>
          Sign Up
        </button>
      </div>
    </form>
  );
};
