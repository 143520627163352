import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useIsMounted } from '../../hooks/useIsMounted';
import logoSvg from '../../images/logo.svg';
import { RoutePaths } from '../../lib/routes';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { SocialLinks } from '../social_links/social_links';
import * as styles from './header.module.less';
import MenuSvg from './menu.inline.svg';
import { Search } from './search';

const Header = () => {
  const isMounted = useIsMounted();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header
      className={classNames(styles.header, {
        isMounted,
        isMobile,
      })}
    >
      <ContentWrapper>
        <div className={styles.content}>
          <Link to="/" className={styles.logo}>
            <img src={logoSvg} alt="Bass Utopia" />
          </Link>

          {isMobile && (
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={classNames(styles.menuButton, {
                [styles.menuButton__active]: isOpen,
              })}
            >
              menu
              <MenuSvg className={styles.menuIcon} />
            </button>
          )}

          <section
            className={classNames(styles.menus, {
              [styles.menus__open]: isOpen,
            })}
          >
            <Search />

            <nav className={styles.mainNav}>
              <ul className={styles.mainNavList}>
                <li>
                  <Link to={RoutePaths.TECHNIQUES} className={styles.mainNavLink}>
                    Techniques
                  </Link>
                </li>
                <li>
                  <Link to={RoutePaths.GIVEAWAYS} className={styles.mainNavLink}>
                    Giveaways
                  </Link>
                </li>
              </ul>
            </nav>

            <nav className={styles.secondNav}>
              <ul className={styles.secondNavList}>
                <li>
                  <Link to={RoutePaths.ABOUT} className={styles.secondNavLink}>
                    About
                  </Link>
                </li>
                <li>
                  <a href="mailto:info@bassutopia.com" className={styles.secondNavLink}>
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
            <div className={styles.socials}>
              <SocialLinks />
            </div>
          </section>
        </div>
      </ContentWrapper>
    </header>
  );
};

export default Header;
