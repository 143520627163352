/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// base second
import React from 'react';
import { Footer } from '../components/footer/footer';
import Header from '../components/header/header';
import { SEO } from '../components/seo';
import { SiteConfig } from '../config';
import * as base from '../styles/base.module.less';

const Layout: React.FC<{}> = (props) => {
  return (
    <>
      <SEO
        title={SiteConfig.siteTitle}
        description={SiteConfig.siteDescription}
        titleTemplate={`%s | Bass Utopia`}
      />
      <div className={base.site}>
        <Header />
        <main className={base.main}>{props.children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
